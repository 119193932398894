<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>

                <v-col cols="8" xs="12" sm="12" md="8" lg="8" xl="8">
                    <v-row>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-select v-model="contentphoto.content_id" :items="loadContents" item-text="cont_titulo" item-value="cont_id" label="Selecione um Conteúdo..." outlined></v-select>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="contentphoto.cont_ft_titulo" label="Título" outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="contentphoto.cont_ft_legenda" label="Legenda" outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="contentphoto.cont_ft_fotografo" label="Fotógrafo" outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="contentphoto.cont_ft_link" label="Link" outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="contentphoto.cont_ft_codigo" label="Código" outlined></v-text-field>
                        </v-col>

                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-switch color="lime" v-model="contentphoto.cont_ft_capa" label="Capa"></v-switch>
                        </v-col>

                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-switch color="lime" v-model="contentphoto.cont_ft_status" label="Figurar"></v-switch>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                            <v-btn class="btn-salvar" color="success" @click="onSubmit">
                                <v-icon class="mdi mdi-check"></v-icon>{{btnLabel}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="4" xs="12" sm="12" md="4" lg="4" xl="4" v-if="contentphoto.cont_ft_foto || preview">

                    <v-card>

                        <v-img v-if="preview" class="img-preview" :src="preview">
                        </v-img>

                        <v-img v-if="contentphoto.cont_ft_foto && !preview" class="img-preview" :src="`${base_url}upload/content_photo/g/${contentphoto.cont_ft_foto}`">
                        </v-img>

                        <v-card-actions class="justify-center">
                            <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                            <v-btn icon>
                                <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-col>

            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'
import {
    mapState
} from 'vuex';

export default {
    created() {
        this.$store.dispatch('loadContents');
    },
    computed: {
        loadContents() {
            if (this.$store.state.contents.items.data !== null)
                return this.$store.state.contents.items.data;
        },
    },
    props: {
        contentphoto: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    cont_ft_id: '',
                    content_id: '',
                    cont_ft_titulo: '',
                    cont_ft_legenda: '',
                    cont_ft_fotografo: '',
                    cont_ft_link: '#',
                    cont_ft_codigo: 'codigo',
                    cont_ft_capa: false,
                    cont_ft_status: false,
                }
            },
        },
        cont_ft_id: '',
        content_id: '',
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    name: "FormContentPhotoComponent",
    data: () => ({

        valid: true,
        preview: null,
        base_url: URL_BASE,
    }),
    methods: {

        onSubmit() {

            const action = this.update ? 'updateContentPhoto' : 'storeContentPhoto';
            const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

            const formData = new FormData();

            if (this.upload !== null)
                formData.append('cont_ft_foto', this.upload);

            formData.append('cont_ft_id', this.contentphoto.cont_ft_id);
            formData.append('content_id', this.contentphoto.content_id);
            formData.append('cont_ft_titulo', this.contentphoto.cont_ft_titulo);
            formData.append('cont_ft_legenda', this.contentphoto.cont_ft_legenda);
            formData.append('cont_ft_fotografo', this.contentphoto.cont_ft_fotografo);
            formData.append('cont_ft_link', this.contentphoto.cont_ft_link);
            formData.append('cont_ft_codigo', this.contentphoto.cont_ft_codigo);
            formData.append('cont_ft_capa', this.contentphoto.cont_ft_capa);
            formData.append('cont_ft_status', this.contentphoto.cont_ft_status);

            this.$store.dispatch(action, formData)
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: msg,
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.$router.push({
                        name: 'contentphotos',
                        params: {
                            content_id: this.contentphoto.content_id
                        }
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.errors = error.response.data.errors
                })
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.upload = files[0];
            this.fileName = this.upload.name;

            this.previewImage(files[0]);
        },
        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            };
            reader.readAsDataURL(file)
        },
        removePreview() {
            this.preview = null;
            this.upload = null;
        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}
</style>
